<template>
  <div class="quota">
    <div class="quota-content">
      <div :class="['quota__main', { actual: isActualNow }]">
        <div>
          <span class="title__description">
            Культура: <b>{{ getQuotaCulture }}</b>
          </span>
          <span class="title__description">
            //&nbsp;Кол-во в буфере:
            <b class="red">{{ getBufferQuota }}</b>
          </span>
        </div>

        <div
          v-if="!isMobile"
          :class="[
            'quota-content__icon-wrap',
            { exporter: isExporter },
            { support: isSupport || isViewer || isArchive },
          ]"
        >
          <Icon
            v-if="!isSupport && !isViewer && !isArchive"
            name="IconPen"
            width="20px"
            height="18px"
            @onClick="handleEditQuota"
          />
          <Icon
            v-if="!isExporter && !isSupport && !isViewer && !isArchive"
            name="IconTrash"
            width="15px"
            height="18px"
            @onClick="handleDeleteQuota"
          />
          <Icon
            name="ArrowDownIcon"
            width="22px"
            height="22px"
            :class="['arrow', { rotated: isOpen }]"
            @onClick="isOpen = !isOpen"
          />
        </div>
      </div>
    </div>
    <el-collapse-transition>
      <QuotaDetail
        v-show="isOpen && !isMobile"
        :quota="quota"
        :is-archive="isArchive"
      />
    </el-collapse-transition>
    <QuotaDeleteModal />
  </div>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import { GET_PREPARE_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import {
  SUPERVISOR_DELETE_QUOTA,
  SUPERVISOR_EDIT_QUOTA,
} from '@/constants/dialogs'
import { mapGetters } from 'vuex'
import { nowUnix } from '@/core'
import Icon from '@/UI/icon/Icon'
import QuotaDeleteModal from '../../dialogs/dialogDeleteQuota/QuotaDeleteModal.vue'
import QuotaDetail from './QuotaDetail.vue'

export default {
  name: 'QuotaContent',
  components: {
    QuotaDetail,
    QuotaDeleteModal,
    Icon,
  },
  props: {
    quota: {
      type: Object,
      required: true,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      visibleDeleteModal: false,
      visibleDeleteNonSlotModal: false,
    }
  },
  computed: {
    ...mapGetters({
      culturesList: GET_PREPARE_CULTURE_FROM_STATE,
      isMobile: GET_IS_MOBILE,
    }),
    getQuotaCulture() {
      if (!this.quota.culture_id) {
        return 'Любая'
      }
      let culture = this.quota.culture_name

      if (this.quota.culture_harvest_year) {
        culture += ` ${this.quota.culture_harvest_year} года`
      }

      return culture
    },
    getBufferQuota() {
      return this.quota.buffer_quota
    },
    isActualNow() {
      return this.quota.time_from < nowUnix() && this.quota.time_to > nowUnix()
    },
  },
  methods: {
    handleEditQuota() {
      this.setDialog({
        name: SUPERVISOR_EDIT_QUOTA,
        data: { ...this.quota, isEdit: true },
        visible: true,
      })
    },

    handleDeleteQuota() {
      this.setDialog({
        name: SUPERVISOR_DELETE_QUOTA,
        data: { id: this.quota.id },
        visible: true,
      })
    },
  },
}
</script>

<style lang="sass">
.quota
  flex: 1 0 71%

.quota-content
  display: flex
  justify-content: space-between
  align-items: center

  &__icon-wrap
    flex: 0 0 87px
    display: flex
    align-items: center
    margin-right: 15px
    &.exporter
      flex: 0 0 58px
      margin-right: 0
    &.support
      flex: 0 0 29px
      margin-right: 0
    .arrow
      transition: transform 0.3s ease
      &.rotated
        transform: rotate(180deg)
      &.iq-icon:hover *
        fill: none
        stroke: $color-blue-primary

  &__icon
    font-size: 20px
    cursor: pointer
    color: $color-black
    transition: color .3s ease
    & + &
      margin-left: 10px
    &:hover
      color: $color-blue

  .iq-icon
    cursor: pointer
    *
      transition: fill .3s ease, stroke .3s ease
    &:hover *
      fill: $color-blue-primary

.quota__main
  flex: 2 0 250px
  display: flex
  justify-content: space-between
  align-items: center
  margin-right: 14px
  padding: 9px 13px
  background: #F0F1F7
  border: 1px solid #DCDCDC
  border-radius: 8px
  font-weight: 500
  font-size: 14px
  line-height: 16px
  letter-spacing: 0.5px
  &.actual
    background: #F0F9EB
  &.mod-child
    flex: 2 0 223px
  .title__description
    color: $grey-font
    .red
      color: $color-orange-primary
  .title__unimproved
    font-weight: 900
    color: $color-orange-primary

@media (max-width: 1200px)
  .quota-content
    flex-direction: column
    flex: auto
    align-items: flex-start
    width: 100%
    .quota__main
      flex: auto
      margin-right: 0
      border: none
      background: #fff
      padding: 16px 6px
      &.actual
        background: #F0F9EB
    &__main-ts
      justify-content: space-between
      font-size: 12px
      margin-bottom: 0
      &-count
        font-size: 12px
</style>
